

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const ERROR_CATEGORY = "ERROR_CATEGORY";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";

export const GET_SUBCATEGORIES = "GET_SUBCATEGORIES";
export const GET_SUBCATEGORY = "GET_SUBCATEGORY";

export const ERROR_SUBCATEGORY = "ERROR_SUBCATEGORY";
export const ERROR_TYPE = "ERROR_TYPE";
export const GET_TYPES = "GET_TYPES";
export const ERROR_EMAIL = "ERROR_EMAIL";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";



export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const ERROR_PRODUCT = "ERROR_PRODUCT";
export const FILTERING_PRODUCTS = "FILTERING_PRODUCTS";
export const FILTERING_PRODUCTS_BYSUB = "FILTERING_PRODUCTS_BYSUB";
export const PRODUCTS_SEARCH = "PRODUCTS_SEARCH";



