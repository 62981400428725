import React, { useEffect,useState } from 'react'
import 'antd/dist/antd.css'
import {connect} from "react-redux"
import {getSubCategories} from  "../../actions/subCategory.action"
import Spinner from "../Layout/Spinner"
import ProductsItems from "./ProductItems"
import { filtering} from  "../../actions/product.action"

import { Menu } from 'antd';

import {  CheckOutlined } from '@ant-design/icons';

const { SubMenu } = Menu;



const SubCategory = ({categoryID ,getSubCategories,subCategories : {subCategories,loading} , products : {products , filtered},types,filtering }) => {
    useEffect(()=>{
     getSubCategories(categoryID)
    
    },[categoryID]) 

    function handleClick(e) {
      console.log(e)
      filtering({filters: { types: e.key  }})
    }
   
          return loading ?<Spinner /> : (
            <div className="container-fluid">
             <div className="row my-5">
             <div className="col-sm-4">
             <Menu onClick={handleClick} style={{ width: 256 }} mode="vertical">
             {types && subCategories.map((sub , i) =>
             <SubMenu key={`sub${i+1}`} icon={<CheckOutlined />} title={sub.title}>

               {types && types.map(type  => 
                <>
                { type.subCategory.id === sub._id &&
                  <Menu.Item key={type._id} >{type.title}</Menu.Item>
                }
                </>
                )}
           </SubMenu>
             )} 
              
            </Menu>
                
                {/* <Accordion defaultActiveKey="0">
                {types && subCategories.map((sub , i) => 
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey={`${i}`}>
                    {sub.title}
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={`${i}`} >
                    
                      <TypesItem  Types ={types && types.filter(type => type.subCategory.id === sub._id )}/>
                   
                  </Accordion.Collapse>
                </Card>  
                 
                )}
              </Accordion> */}
              </div>
            <div className="col-sm-7 my-2">
                {subCategories.length > 0  && <ProductsItems  subCategory={subCategories[0]}/>}
            </div> 
          </div>
        </div>
    )
}

const mapStateToProps = state =>({
    subCategories : state.subCategories,
    products : state.products
})
export default connect(mapStateToProps ,{getSubCategories  ,filtering})(SubCategory)
