import axios from "axios";
import {
  GET_PRODUCTS,
  ERROR_PRODUCT,
  FILTERING_PRODUCTS ,
  FILTERING_PRODUCTS_BYSUB,
  PRODUCTS_SEARCH

} from "./types";
import {Url} from "./config"
// GET PRODUCTS
export const getProducts = () => async (dispatch) => {
  try {
    const res = await axios.get(`${Url}/api/products`);
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR_PRODUCT,
      payload: { msg: error.response, status: error.response },
    });
  }
};
// filter Category
export const filtering = (filter) => (dispatch) => {
  dispatch({ type: FILTERING_PRODUCTS, payload: filter });
};

// filter Category
export const filterbysub = (filter) => (dispatch) => {
  dispatch({ type: FILTERING_PRODUCTS_BYSUB, payload: filter });
};


// Search Product
export const ProductSearch = (text) => (dispatch) => {
  dispatch({ type: PRODUCTS_SEARCH, payload: text });
};
