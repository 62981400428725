import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter as Router ,Route , Switch} from 'react-router-dom'
import Aboutus from './pages/aboutus';
import Contact from './pages/contact';
import OilAndGas from './pages/oil&Gas';
import HomePage from './pages/HomePage';
import "./css/font-awesome.min.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/about" exact component={Aboutus} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/:category" exact component={OilAndGas} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
