import React ,{useEffect}from 'react'
import Footer from '../components/Layout/Footer'
import NavBar from '../components/Layout/NavBar'
import {Tab ,Tabs} from "react-bootstrap"
import {Link } from "react-router-dom"
import {connect,useSelector} from "react-redux"
import {getOneCategory} from "../actions/category.action"
import {getTypes} from "../actions/types.action"
import {getCategory} from "../actions/category.action"
import SubCategory from "../components/SubCategory/SubCategory"
import Spinner from "../components/Layout/Spinner"
import SearchProductsItem from "./SearchedProductsItem"

const OilAndGas = ({getOneCategory , getTypes,match ,getCategory, categories : {category , loading},types:{types} }) => {
     useEffect(()=>{
      getOneCategory(match.params.category)
      getTypes()
      getCategory()
     },[match.params.category ])

     const searched = useSelector(state => state.products.searched)
    return !category ? <Spinner />  : (
     
     <div style={{backgroundColor : "#eee"}}>
    <div style={{background:"rgba(1,1,1,0.8)",color:"#fff",fontSize:"16px",position:"absolute",top:"0px",width:"100%",zIndex:"1000"}}>
		
        <NavBar show="true" />
    </div>	
    
    <div id="business"  >
    <div className="container" style={{marginTop:"150px",minHeight:"200px"}}>
	  <div className=" text-center" style={{backgroundColor : "rgba(63, 57, 57, 0.7)", padding:"10px" , borderRadius : "7px" }}>
      <h2 className="customFadeInUp text-white" >
       {category.title}    
       </h2>
      <p className="customFadeInUp text-white lead" dangerouslySetInnerHTML={{__html: category.description}}></p></div>
	  <div className="col-lg-6 col-md-12" style={{textAlign:"right",position:"relative",top:"0px"}}>
	  
	  <div className="btn-group-vertical" style={{width:"100%"}}>
				  	  </div>
    </div>


	</div>
  </div>
  {searched && searched.length > 0 ? <SearchProductsItem filtered={searched}/> :
  <section id="category" >
     <SubCategory  categoryID={category._id} types={types} />

  </section>}
  <Footer />



        </div>
    )
}
const mapStateToProps = state =>({
  categories : state.categories,
  types : state.types
})
export default  connect(mapStateToProps , {getOneCategory ,getTypes ,getCategory})(OilAndGas)
