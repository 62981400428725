import React, { useEffect } from 'react'
import {connect} from "react-redux"
import Galary from "./galary";
const ProductItems = ({subCategory  ,products:{filtered}}) => {
   
    return (
        <>
         {filtered && filtered.map(product => 
         <div className="text-center ">
            <div className="galary">
            <h3 className="text-teal p-3">{product.title}</h3>
         </div>
            <div className="galary">
            {product.imagegalary && product.imagegalary.length > 0 ? (
                <Galary pictures={product.imagegalary} />
        ) : null}
        
        </div>
            <p className="text-justify" dangerouslySetInnerHTML={{__html :product.description}}></p>
         </div>) }
        </>
    )
}
const mapStateToProps = state =>({
    products : state.products
})
export default connect(mapStateToProps )(ProductItems)
