import React, { useEffect } from 'react'
import Galary from "../components/SubCategory/galary";
import NavBar from "../components/Layout/NavBar"
import {Col , Row} from "react-bootstrap"
const ProductItems = ({filtered}) => {
   
    return (
   
  <>
  <div className="container">
   <div className=" text-center my-2" style={{backgroundColor : "rgba(63, 57, 57, 0.7)", padding:"10px" , borderRadius : "7px" }}>
      <h2 className="customFadeInUp text-white" >
          Search Result
       </h2>
     </div>
     
     <Row>
        
         {filtered && filtered.map(product => 
         <Col md={6}>
        <div className="text-center  ">
            <div className="galary">
            <h3 className="text-teal p-3">{product.title}</h3>
         </div>
            <div className="galary">
            {product.imagegalary && product.imagegalary.length > 0 ? (
                <Galary pictures={product.imagegalary} />
        ) : null}
        
        </div>
            <p className="text-justify text-dark" dangerouslySetInnerHTML={{__html :product.description}}></p>
         </div>
         </Col>
         ) }
     </Row>
     </div>
        </>
    )
}

export default ProductItems
