import React from 'react'
import {Carousel} from 'react-bootstrap'
import NavBar from "../components/Layout/NavBar"
import Footer from "../components/Layout/Footer"
import {Link } from "react-router-dom"
import { connect } from 'react-redux'
import Spinner from '../components/Layout/Spinner'
import { useEffect } from 'react'
import {getCategory} from "../actions/category.action"
import {useSelector} from "react-redux"
import SearchProductsItem from "./SearchedProductsItem"
const HomePage = ({getCategory ,categories:{categories,loading}}) => {
  useEffect(()=>{
    getCategory()
  },[])
  const searched = useSelector(state => state.products.searched)

    return loading ? <Spinner /> : 
    <>
       
<NavBar  />
        <Carousel  >
            <Carousel.Item interval={6000}>
              <div className="header__overlay"></div>
              <img
                className="d-block w-100"
                height="550"
                src="./images/slider/1.jpg"
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>OIL&GAS</h3>
                <p>Oil & Gas Division is a key supplier and service provider to Oil and Gas, Petrochemical, Energy, Construction Industries and other Industrial sectors.</p>
                <Link to="/oil" className="btn btn-primary px-3">Oil & Gas</Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={6000}>
            <div className="header__overlay"></div>

              <img
                className="d-block w-100"
                height="550"
                src="./images/slider/3.jpg"
                alt="Third slide"
              />
              <Carousel.Caption>
                <h3>EQUIPMENT</h3>
                <p>Emdad Equipment &Oil Field Supplier Heavy Equipment Division has expertise in the supply of a wide range of heavy equipment's such as transport and process equipment for Oil & Gas industry. These equipment's are used across various industries such as construction, fuel storage and distribution, transportation, waste disposal, and oilfield and port facilities.</p>
                <Link to="/equipment" className="btn btn-primary px-3">EQUIPMENT</Link>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={6000}>
            <div className="header__overlay"></div>
              <img
                className="d-block w-100"
                height="550"
                src="./images/slider/2.jpg"
                alt="Third slide"
              />
              <Carousel.Caption>
                <h3>EQUIPMENT</h3>
                
              </Carousel.Caption>
            </Carousel.Item>
           
          </Carousel>
        
          <div class='globe_seperator' >
          <i class='glyphicon glyphicon-phone' style={{marginTop:"-20px"}}></i>
          </div>

          {searched && searched.length > 0 ? <SearchProductsItem filtered={searched}/> :
          <div className='row infoBox' style={{width:"100%",paddingTop:"20px",paddingBottom:"20px",marginLeft: "0px"}}>
        <div className='col-lg-4' style={{paddingLeft:"50px"}}>

        </div>
          <div className='col-lg-8 ' style={{background:"rgba(1,1,1,0.8)"}}>
          <div className="row text-white lead text-justify" style={{width:"100%",padding:"20px",paddingBottom:"20px"}}>

          <h4>About Us  :</h4>
          <p>
          Founded in May 1999, our company started as D.A. Trading LLC based in Dubai, U.A.E  which distributes and sells numerous products and services in the field of Oil & Gas Industry in Libya and other countries.  Our unique capacity to distribute and sell oil & gas related commodities , heavy equipment, cars and trucks, and  backed with our well experienced professionals makes us an invaluable partner of previously known companies such as Mediterranean Oil Services - (Germany) and Umm Al Jawaby (London, U.K).
          </p>
          <p>To serve our clients better as well as to keep up with the competitive market of Libyan Oil and Gas Market, EMDAD EQUIPMENT & OIL FIELD SUPPLIER. was established in September 2011.in Libya and Canada  with Our solid 20 years of experience of business coverage within Libyan territories and already established international suppliers support our goal to serve our clientele with all their requirements.  Our team acquired wide knowledge and experience within long years of working that also provides products and services to the Oilfield Industry in Libya.  We take pride of company's vision and dedication to offer customer's satisfaction. Our company has built enviable reputation with expertise in product and brand outsourcing for all our client’s needs.</p>
          <p>
          We continued to cater to various oilfield services, oilfield equipment’s, oilfield supplies, industrial, refinery, petroleum equipment supplies , all types of Heavy equipment and vehicles (passenger cars, SUV 4X4, commercial vehicles and special use trucks and vehicles) as well as different brands of heavy equipment’s.  We also supply genuine spare parts for all kinds of Machinery, heavy equipment and vehicles and different brand of tires and batteries.  

          We would be delighted to give whatever requirement you may need , even on short notice, we will be pleased to offer you competitive quotations for all your inquiries. We are confident that we can earn the privilege of being your service provider if given the chance.  
          Rest assured that we will be your devoted and dependable business partner on all your future needs.  We look forward to forming a mutually rewarding business relationship.
          </p>
        </div>
        </div>  
      </div>}
        
        <Footer />
        </>
    
}
const mapStateToProps = state =>({
  categories : state.categories
})
export default connect(mapStateToProps , {getCategory})(HomePage)
