import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

const Footer = ({categories : {categories}}) => {
    return (
        <footer className="footer-bs" style={{marginBottom:"0px"}}>
        <div className="row">
        	<div className="col-md-4 footer- /and animated fadeInLeft">
            	<h2>
                    	
                     {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img  src="images/logos/logoBTC.png" className=" /and-img img-responsive" style={{minHeight:"60px",minWidth:"160px",maxHeight:"80px",width:"auto"}} />
	            </h2>
               
                <p>{new Date().getFullYear()+1}{"-"} {new Date().getFullYear()} &copy; </p>
            </div>
        	<div className="col-md-5 footer-nav animated fadeInUp">
            	
            	
			
            	<div className="col-md-4">
                    <ul className="list">
                        <li><Link to="/">ABOUT US</Link></li>
                        { categories && categories.map(category => 
                <li>
                            <Link
                                to={category.url}
                                
                            >
                                {category.title}
                            </Link>
                            </li>
                  )}
                        <li><Link to="/contact"> CONTACT US </Link></li>
                    </ul>
                </div>
            </div>
        	<div className="col-md-3 footer-social animated fadeInDown">
            	<h4>Contact Us</h4>
            	<ul>

					<li><a href="mailto: info@emdadly.com">Email us</a></li>
                </ul>
				<h5><b>Phone List</b></h5>
                Canada : 
				<span className='phone'> +1 (647)6218393 </span>
                <br />
                Libya&nbsp;&nbsp;&nbsp;&nbsp; : 
				<span className='phone'>+218(91)3204780 </span>
				
            </div>
        </div>
    </footer>
    )
}
 const mapStateToProps = state =>({
     categories : state.categories
 })
export default connect(mapStateToProps)(Footer)
