import React, { useRef, useEffect } from "react";
import { ProductSearch } from "../../actions/product.action";
import {Form,FormControl } from "react-bootstrap";
import { connect } from "react-redux";

const ContactFilter = ({ ProductSearch, products: { searched } }) => {
  useEffect(() => {
    if (searched === null) {
      text.current.value = "";
    }
  });
  const text = useRef("");

  const onChange = (e) => {
    ProductSearch(e.target.value);
  };

  return (
    <Form inline>
      <FormControl
      className="mr-sm-2"
        name="title"
        type="text"
        ref={text}
        onChange={onChange}
        placeholder="Search..."
      />
    </Form>
 
  );
};

const mapStateToProps = (state) => ({
  products : state.products
});

export default connect(mapStateToProps, { ProductSearch })(ContactFilter);
