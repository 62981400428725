import axios from "axios";
import {
  GET_CATEGORIES,
  ERROR_CATEGORY,
  GET_CATEGORY,
  CLEAR_CATEGORY,
  
} from "./types";
import {Url} from "./config"

export const clearCategory = () =>  (dispatch) =>{
 dispatch({
   type : CLEAR_CATEGORY
 })
}

// GET CONFERANCES
export const getCategory = () => async (dispatch) => {
  try {
    const res = await axios.get(`${Url}/api/category`);
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CATEGORY,
      payload: { msg: error.response, status:error.response&& error.response.status },
    });
  }
};

// GET CONFERANCES
export const getOneCategory = (url) => async (dispatch) => {
  try {
    const res = await axios.get(`${Url}/api/category/${url}`);
    dispatch({
      type:GET_CATEGORY ,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CATEGORY,
      payload: { msg: error.response, status:error.response&&  error.response.status },
    });
  }
};