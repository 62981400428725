import React from 'react'
import Footer from '../components/Layout/Footer'
import NavBar from '../components/Layout/NavBar'
import {Link } from "react-router-dom"
import ContactUs from "./ContactUs"
import SearchProductsItem from "./SearchedProductsItem"
import {useSelector} from "react-redux"

const Contact = () => {
  const searched = useSelector(state => state.products.searched)

    return (
     <>
    <div style={{background:"rgba(1,1,1,0.8)",color:"#fff",fontSize:"16px",position:"absolute",top:"0px",width:"100%",zIndex:"1000"}}>
		
        <NavBar />
    </div>	


    <div style={{position:"absolute",color:"#fff",maxWidth:"40%",display:"block",marginLeft:"0px",marginTop:"70px"}} >
 {/* eslint-disable-next-line  */}
 <Link to="/">
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img  src="images/logos/logoBTC.png" className="brand-img img-responsive" style={{minHeight:"70px" ,minWidth:"160px",maxHeight:"80px",width:"auto",}} />
            </Link>
</div>

  <div className="business-hero" style={{
  background:"url(./images/contactus/banner.jpg)",
  backgroundSize:"cover",backgroundPosition:"center 0",overflow:"hidden",position:"relative",
  minHeight:"70vh",paddingBottom:"10px",zIndex:"-10"}} >

  
    <div className="container" style={{marginTop:"150px",minHeight:"200px"}}>
	<div className=" text-center">
      <h2 className="customFadeInUp" >
       GET IN TOUCH      </h2>
      <p className="customFadeInUp text-info">
       WE LIKE TO HEAR FROM YOU!<br />
	  
	  </p></div>
	  <div className="col-lg-6 col-md-12" style={{textAlign:"right",position:"relative",top:"0px"}}>
	  
	  <div className="btn-group-vertical" style={{width:"100%"}}>
				  	  </div>
    </div>


	</div>
  </div>

  {searched && searched.length > 0 ? <SearchProductsItem filtered={searched}/> :<ContactUs />}

 
<div class="col-lg-12" style={{background:"#a3a3a3",paddingLeft:"0px",paddingRight:"0px",overflowX:"hidden"}}>
	
    {/* <!----------- Footer ------------> */}

  <Footer />

</div>

        </>
    )
}

export default Contact
