import {
  GET_CATEGORIES,
  GET_CATEGORY,
  ERROR_CATEGORY,
  CLEAR_CATEGORY,

} from "../actions/types";

const inialState = {
  categories: [],
  category: null,
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: payload,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: payload,
        loading: false,
      };
    case ERROR_CATEGORY:
      return {
        ...state,
        error: payload,
        loading: false,
      };
      case CLEAR_CATEGORY :
        return {
          ...state,
          category: null,
          loading: true,
        }

    default:
      return state;
  }
}
