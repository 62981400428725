import {
  GET_PRODUCTS,
  GET_PRODUCT,
  ERROR_PRODUCT,
  FILTERING_PRODUCTS,
  FILTERING_PRODUCTS_BYSUB,PRODUCTS_SEARCH
} from "../actions/types";

const inialState = {
  products: [],
  product: null,
  loading: true,
  filtered:[],
  searched : [],
  error: {},
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
        loading: false,
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: payload,
        loading: false,
      };
      case PRODUCTS_SEARCH:
      return {
        ...state,
        searched: state.products.filter((product) => {
          const regex = new RegExp(`${action.payload}`, "gi");
          return (
            action.payload !=="" &&
            (product.title.match(regex) ||
            product.description.match(regex) )
          );
        }),
      };
      case FILTERING_PRODUCTS:
        return {
          ...state,
          filtered: state.products.filter((product) => {
            console.log(action.payload.filters)
            return (
              product.type.id === action.payload.filters.types 
            );
          }),
        };
        case FILTERING_PRODUCTS_BYSUB:
        return {
          ...state,
          filtered: state.products.filter((product) => {
            return (
              product.subCategory.id === action.payload.filters.subcategory 
            );
          }),
        };
    case ERROR_PRODUCT:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
