import axios from "axios";
import {
  ERROR_EMAIL, ERROR_TYPE, 
} from "./types";
import {Url} from "./config"



// send Email from web site to info@zog.edu.ly
export const sendEmailFromWebSite = (data) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = axios.post(
      `${Url}/api/sendemailfromwebsite`,
      data,
      config
    );
    console.log(res.data);
  } catch (error) {
    dispatch({
      type: ERROR_EMAIL,
      payload: {
        msg: error.response.statusText,
        status: error.response.status,
      },
    });
  }
};



