import React from 'react'
import Footer from '../components/Layout/Footer'
import NavBar from '../components/Layout/NavBar'

const Aboutus = () => {
    return (
     <>
    <div style={{background:"rgba(1,1,1,0.8)",color:"#fff",fontSize:"16px",position:"absolute",top:"0px",width:"100%",zIndex:"1000"}}>
		
        <NavBar />
    </div>	


<div style={{position:"absolute",color:"#fff",maxWidth:"40%",display:"block",    marginLeft:"0px",marginTop:"70px"}} >
 {/* eslint-disable-next-line  */}
<a href="#">
{/* eslint-disable-next-line jsx-a11y/alt-text */}
<img  src="images/logos/logoBTC.png" className="brand-img img-responsive" style={{minHeight:"70px" ,minWidth:"160px",maxHeight:"80px",width:"auto"}} />
</a>
</div>

  <div className="business-hero" style={{
  background:"url(./images/about.jpg)",
  backgroundSize:"cover",backgroundPosition:"center 0",overflow:"hidden",position:"relative",
  minHeight:"90vh",zIndex:"-10",
  clipPath: "polygon(0 0, 100% 0, 100% 100%, 70% 90%, 21% 100%, 0 100%)"

  }} >

  
    <div className="container" style={{marginTop:"150px",minHeight:"200px"}}>
	<div className=" text-center text-white">
      <h2 className="customFadeInUp" >
	      </h2>
     </div>
	  <div className="col-lg-6 col-md-12" style={{textAlign:"right",position:"relative",top:"0px"}}>
	  
	  <div className="btn-group-vertical" style={{width:"100%"}}>
		  
				  	  </div>
    </div>


	</div>
  </div>


<div className='row infoBox' style={{width:"100%",paddingTop:"20px",paddingBottom:"20px",marginLeft: "0px"}}>
<div className='col-lg-4' style={{paddingLeft:"50px"}}>

</div>
<div className='col-lg-8 ' style={{background:"rgba(1,1,1,0.8)"}}>
 <div className="row text-white lead text-justify" style={{width:"100%",padding:"20px",paddingBottom:"20px"}}>

<h4>About Us  :</h4>
<p>
Founded in May 1999, our company started as D.A. Trading LLC based in Dubai, U.A.E  which distributes and sells numerous products and services in the field of Oil & Gas Industry in Libya and other countries.  Our unique capacity to distribute and sell oil & gas related commodities , heavy equipment, cars and trucks, and  backed with our well experienced professionals makes us an invaluable partner of previously known companies such as Mediterranean Oil Services - (Germany) and Umm Al Jawaby (London, U.K).
</p>
<p>To serve our clients better as well as to keep up with the competitive market of Libyan Oil and Gas Market, EMDAD EQUIPMENT & OIL FIELD SUPPLIER. was established in September 2011.in Libya and Canada  with Our solid 20 years of experience of business coverage within Libyan territories and already established international suppliers support our goal to serve our clientele with all their requirements.  Our team acquired wide knowledge and experience within long years of working that also provides products and services to the Oilfield Industry in Libya.  We take pride of company's vision and dedication to offer customer's satisfaction. Our company has built enviable reputation with expertise in product and brand outsourcing for all our client’s needs.</p>
<p>
We continued to cater to various oilfield services, oilfield equipment’s, oilfield supplies, industrial, refinery, petroleum equipment supplies , all types of Heavy equipment and vehicles (passenger cars, SUV 4X4, commercial vehicles and special use trucks and vehicles) as well as different brands of heavy equipment’s.  We also supply genuine spare parts for all kinds of Machinery, heavy equipment and vehicles and different brand of tires and batteries.  

We would be delighted to give whatever requirement you may need , even on short notice, we will be pleased to offer you competitive quotations for all your inquiries. We are confident that we can earn the privilege of being your service provider if given the chance.  
Rest assured that we will be your devoted and dependable business partner on all your future needs.  We look forward to forming a mutually rewarding business relationship.
</p>
</div>
</div>  
</div>
<div class="col-lg-12" style={{background:"#a3a3a3",paddingLeft:"0px",paddingRight:"0px",overflowX:"hidden"}}>
	
    {/* <!----------- Footer ------------> */}

  <Footer />

</div>

        </>
    )
}

export default Aboutus
