import {
  GET_TYPES,
  ERROR_TYPE,
} from "../actions/types";

const inialState = {
  types: [],
  loading: true,
  error: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = inialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_TYPES:
      return {
        ...state,
        types: payload,
        loading: false,
      };

    case ERROR_TYPE:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
