import { combineReducers } from "redux";
import categories from "./category.reduser";
import types from "./types.reduser";
import subCategories from "./subCategory.reduser";
import products from "./product.reduser";
import alert from "./alert"


export default combineReducers({
  
  categories,
  types,
  products,
  subCategories,
  alert
 
});
