import axios from "axios";
import {
  GET_TYPES,
  ERROR_TYPE, 
} from "./types";
import {Url} from "./config"

// GET CONFERANCES
export const getTypes = (categoryID) => async (dispatch) => {
  try {
    const res = await axios.get(`${Url}/api/types`);
    dispatch({
      type: GET_TYPES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_TYPE,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};



