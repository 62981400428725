import React, { useEffect, useState } from "react";
import {connect} from "react-redux"
import {getCategory ,clearCategory} from "../../actions/category.action"
import { Nav, Container, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./NavBar.style.css";
import ProductSearch from "../products/ProductSearch"
import {getProducts} from "../../actions/product.action" 

const Navbars = ({  getProducts,categories:{categories} ,clearCategory}) => {
  // eslint-disable-next-line
  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };
  useEffect(() => {
    getProducts()
    getCategory()
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      id="header"
       className={scrollPosition > 50 && "sticky"}
    >
      <>
        <Navbar collapseOnSelect expand="lg" sticky="top">
          <Container>
            <Navbar.Brand href="#home">
               <img src="./images/logos/logoBTC.png" alt="logo" width="200" />
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link>
                  <Link
                    to="/"
                  >
                    HOME
                  </Link>
                </Nav.Link>
                { categories && categories.map(category => 
                  <Nav.Link key={category._id}>
                  <Link
                    to={category.url}
                     onClick={()=>clearCategory()}
                  >
                     {category.title}
                  </Link>
                </Nav.Link>
                  )}
               

                <Nav.Link>
                  {" "}
                  <Link
                    to="/contact" 
                  >
                    CONTACT US
                  </Link>
                </Nav.Link>
                <ProductSearch />
              </Nav>
              {/* <Nav className="mr-auto grid-3">
                <Nav.Link className="lang" >
                  AR
                </Nav.Link>
                <Nav.Link className="lang" >
                  EN
                </Nav.Link>
               
              </Nav> */}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    </header>
  );
};
const mapStateToProps = state =>({
  categories : state.categories
})
export default connect(mapStateToProps,{clearCategory ,getProducts} )(Navbars)
