import axios from "axios";
import {
  GET_SUBCATEGORIES,
  ERROR_SUBCATEGORY, 
} from "./types";
import {Url} from "./config"

// GET CONFERANCES
export const getSubCategories = (categoryID) => async (dispatch) => {
  try {
    const res = await axios.get(`${Url}/api/subcategory/sub/${categoryID}`);
    dispatch({
      type: GET_SUBCATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SUBCATEGORY,
      payload: { msg: error.response, status: error.response.status },
    });
  }
};





