import React, { useState } from "react";
import { sendEmailFromWebSite } from "../actions/Email.action";
import { connect } from "react-redux";
import Alert from "../components/Layout/Alert";
import { setAlert } from "../actions/alert";
const ContactUs = ({ sendEmailFromWebSite ,setAlert }) => {
  const [Message, setMessage] = useState({
    email: "",
    name: "",
    website: "",
    Subject: "",
    message: "",
  });

  const { email, name, website, message, Subject } = Message;

  const onChange = (e) =>
    setMessage({ ...Message, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (message === "" && email === "" && name === "") {
      setAlert(
        "Fill All fields",
        "danger"
      );
    } else {
      sendEmailFromWebSite(Message);
      setAlert(
        "Email sent Succesfuly",
        "success"
      );
      setMessage({
        email: "",
        name: "",
        website: "",
        Subject: "",
        message: "",
      });
    }
  };
  return (
    <section class="sections contact py-5" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-xs-12">
           
            <form >
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Name..."
                  name="name"
                  onChange={onChange}
                  value={name}
                />
                <input
                  class="form-control"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChange}
                  placeholder="Email ..."
                />
              </div>

              <div class="form-group">
                <input
                  class="form-control"
                  name="website"
                  onChange={onChange}
                  value={website}
                  type="url"
                  placeholder="Website .."
                />
                <input
                  class="form-control"
                  name="Subject"
                  value={Subject}
                  type="text"
                  onChange={onChange}
                  placeholder="Subject ..."
                />
              </div>

              <textarea
                class="form-control"
                name="message"
                value={message}
                onChange={onChange}
                placeholder="Message ... "
                id=""
                cols="30"
                rows="10"
              ></textarea>
<Alert />
              <button class="form-btn" onClick={onSubmit}>
                Send Email{" "}
              </button>
            </form>
          </div>
          <div class="col-md-6 col-xs-12 ">
            <div class="contact-info">

              <h4>Canada Office</h4>
              <span>
                <i class="fa fa-map-marker mx-3"></i>
                302-2233 Argentia Rd, Mississauga Ontario, L5N2X7, CANADA
              </span>
              <span>
                <i class="fa fa-phone mx-3"></i>
                +1 (647)6218393
              </span>
              <span>
                <i class="fa fa-envelope mx-3"></i>
                info_can@emdadly.com
              </span>
						  <h4>Libya Office</h4>
              <span>
                <i class="fa fa-map-marker mx-3"></i>
              Address  : altareq Alsahel, Joddaam
                        Azzawiya City, LIBYA
              </span>
              <span>
                <i class="fa fa-phone mx-3"></i>
                +218(91)3204780
              </span>
              <span>
                <i class="fa fa-envelope mx-3"></i>
                info@emdadly.com
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default connect(null, { sendEmailFromWebSite, setAlert })(ContactUs);
